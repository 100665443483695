*{
  margin: 0;
  padding: 0;
  font-size: 12px;
}

:root{
  --main-color : grey;
  --dark-color : #0d0d0d;
  --light-color: #fff;
}

canvas {
  display: block;
}

html,
body
{
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  color: grey;
}

li a, button {
  text-shadow: 0 0 2px #a1a1a1;
  mix-blend-mode: difference;
}

a {
  text-decoration: none;
  color: inherit;
}

.home nav{
  align-self: center;
  justify-self: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 9999;
  mix-blend-mode: difference;
}

.exp h1 {
  align-self: center;
  font-size: 1rem;
  top: 4rem;
  font-weight: 400;
  mix-blend-mode: difference;
  cursor: pointer;
  pointer-events: visible;
}

li {list-style: none;}

button {
  background-color: unset;
  border: unset;
  color: var(--main-color);
  text-transform: uppercase;
  text-shadow: 0 0 2px white;
}

/* Dropdown */

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  min-width: 160px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  transition: .3s all;
}

.dark-drop a {
  color: var(--dark-color);
}

.dropdown-content a{
  padding: .5rem 0 !important;
  color: grey;
  transition: .3s all;
}


.dropdown-content a:first-child{
  margin-top: 1rem;
}

.dropdown-content a:hover{
  color: var(--ligh-color);
}

.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  transition-delay: .3s all ease-in-out;
}

.desk-nav {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  mix-blend-mode: difference;
  z-index: 9999;
}
.desk-nav a {
  padding: 1rem 0;
}

.nav-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  pointer-events: none;
 }

 .nav-grid ul {
  pointer-events: visible;
 }

 .nav-center {
  width: 80vw;
  height: 75vh;
  max-width: 1702px;
  pointer-events: none;
  aspect-ratio: 3/2;
  display: block;
 }

.laniakea .slides {
  padding: unset;
}

.laniakea .slides:first-of-type {
  padding: 0 1rem;
}

.laniakea section .column{
  height: 100vh;
  width: 100%;
}

/* HOME PAGE */
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  text-transform: uppercase;
  justify-content: center;
}

.container li:nth-child(2) {
  margin-left: 1rem;
}

.container nav, h1 {
  z-index: 999;
}

h1:hover, li:hover, button:hover, a:hover {
  cursor: pointer;
  color: var(--light-color);
  text-shadow: 0 0 2px white;
}

.webgl
{
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

/* Button animation*/
.soundbtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: visible;
  height: 14px;
}

.wave {
  display: flex;
  align-items: center;
  height: 30px;
  mix-blend-mode: difference;
}

.wave span {
  height: 10px;
  margin-right: 4px;
  display: block;
  background-color: var(--main-color);
  width: 1px;
  transform: scaleY(1);
}

.wave span {
  animation: none;

}

.active .wave span {
  animation: line 1000ms cubic-bezier(0.250, 0, 0.705, 1) infinite alternate;
  background-color: white;
}

@keyframes line{
  0%{height: 10px;transform: scaleY(1); }
  100%{height: 18px;transform: scaleY(1.05);}
}

.wave span:nth-child(1) {
  animation-delay: 200ms;
}

.wave span:nth-child(3) {
  animation-delay: 400ms;
}

.wave span:nth-child(5) {
  animation-delay: 600ms;
}

.wave span:nth-child(2) {
  animation-delay: 100ms;
  animation-direction: alternate-reverse;
}

.wave span:nth-child(4) {
  animation-delay: 500ms;
  animation-direction: alternate-reverse;
}

.wave span:nth-child(6) {
  animation-delay: 600ms;
  animation-direction: alternate-reverse;
}

/* IMAGE BOX */
.lightbox {
  z-index: 0;
  opacity: 0;
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

/* .lightbox button {
  margin-top: 1rem;
} */

.lightbox img {
  height: 75vh;
  width: 100%;
  object-fit: contain;
}

.img-active:hover {
  cursor: url(./static/icons/cursormin.svg), auto;
}

/* LOADING BAR */
.loading-bar
{
  position:absolute;
  top: 49.7%;
  width: 100%;
  height: 4px;
  background: #ffffff;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.5s;
  will-change: transform;
  z-index: 999;
}

.loadpercent {
  color: var(--light-color);
  position: absolute;
  bottom: 4rem;
  right: 3.5rem;
  text-transform: uppercase;
  font-size: 12px;
}

.loading-bar.ended
{
  transform-origin: top right;
  transition: transform 1.5s ease-in-out;
}

.loadpercent.ended {
  opacity: 0;
}

.active{
  color: var(--light-color) !important;
}

.introduction {
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  text-align: center;
  text-transform: uppercase;
}

.introduction img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.introduction button {
  color: var(--ligh-color);
}

/* ABOUT PAGE */
#about {
  position: absolute;
  top: 0;
  z-index: 9999;
  background-color: var(--dark-color);
  height: 100%;
  overflow-y: scroll;
}

.about-grid {
  display: grid;
  height: 100%;
  color: var(--dark-color);
}

.about-grid .parcour ul {
  flex-direction: column;
}

.parcour ul li a:hover{
  text-shadow: 0 0 2px #fff;
}

.parcour {
  padding: 2rem;
}

.bio {
  padding: 5rem 1rem 1rem 2rem;
  color: black;
  background: white;
}

.bio p {
  font-size: 2.5rem;
}
.parcour p, .parcour ul li, .parcour a {
  font-size: 16px;
  line-height: 1.35;
}

.parcour ul li, .parcour ul li a {
  line-height: 16px;
  text-shadow: inherit;
}

.parcour {
  background-color: var(--light-color);
}

.lighter-shadow {
  text-shadow: unset;
}

.light-shadow {
  text-shadow: 0px 0px 2px var(--light-color);
  text-shadow: unset;

}

.light-shadow-4 {
  text-shadow: 0 0 4px var(--light-color);
  text-shadow: unset;
}

.dark-shadow-2 {
  text-shadow: 0px 0px 2px var(--dark-color);
  text-shadow: unset;
}

.blight-shadow {
  text-shadow: 0px 0px 10px var(--light-color);
  text-shadow: unset;
}

.dark-shadow {
  text-shadow: 0px 0px 10px #666666;
  text-shadow: unset;
}

.darkbg{
  color:  var(--light-color);
  background-color: var(--dark-color);
}


.contact {
  color: var(--light-color);
  mix-blend-mode: difference;
  font-size: 12px;
  line-height: 1.1em;
  text-transform: uppercase;
}

.contact a {
  font-size: 12px;
}

.section-footer {
  display: block;
  width: 100%;
  height: 6rem;
  background: linear-gradient(to top, #000 0%, #0000 100%);
  mix-blend-mode: darken;
  background-color: white;
  display: flex;
  position: fixed;
  bottom: 0;
}

.arrow-wrap {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  mix-blend-mode: difference;
}

.arrow-down {
  content: "";
  width: 16px;
  height: 16px;
  mix-blend-mode: difference;
  border-top: 0.2em solid #fff;
  border-right: 0.2em solid #fff;
  margin-left: 1em;
  margin-right: 0.5em;
  display: inline-block;
  transform: rotate(135deg);
  z-index: 999;
}

.close-wrapper {
  position: fixed;
  bottom: 0;
  width: 32px;
  height: 32px;
  opacity: 0.8;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.close-wrapper a {
  text-transform: uppercase;
  color: white;
  font-size: 1.3rem;
}

.cls::before {
  content: url("./static/icons/clsbtn.png");
  display: inline-block;
  margin-right: .5rem;
  margin-top: 3px;
}

.close {
  padding: 9px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  display: block;
  position: absolute;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: var(--light-color);
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}


/* Series STYLES */
#series {
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100vw;
}

#series nav ul li {
  text-transform: uppercase;
}

#series nav ul li:nth-child(2) {
  margin-left: 1rem;
  text-transform: uppercase;
}

.serie-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); ;
  grid-gap: 2rem;
  margin-bottom: 1rem;
 }

 .serie-grid .column {
  width: auto;
  padding-bottom: 2rem;
 }

 .namecount {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  height: 88vh;
  position: absolute;
  width: 100%;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  mix-blend-mode: difference;
  pointer-events: none;
  padding: 2rem 0;
  text-shadow: 0 0 2px #a1a1a1;
 }

.namecount h1 {
  pointer-events: visible;
  font-weight: 400;
}

/* CLASS STYLES */
.flex {display: flex;}
.dir-column { flex-direction: column;}
.row { flex-direction: row;}

.f-1 {
  flex: 1
}
.f-2 {
  flex: 2
}
.f-3 {
  flex: 3
}

.credits a {font-size: 10px !important; line-height: 10px !important;}
.credits li {line-height: 10px !important;}


.justify-center {justify-content: center;}
.self-end {justify-self: end;}
.self-start {justify-self: start;}
.align-center {align-items: center;}

.absolute {position:absolute;}
.top-50 {top:50%}
.right-0 {right: 0rem;}
.right-2 {right: 2rem;}
.block {display: block;}
.d-nonde {display: none;}
.w-full {width: 100%;}
.w-auto {width: auto;}

.h-full {height: 100%;}

.mb-05 {margin-bottom: .5rem;}
.mb-1  {margin-bottom: 1rem;}
.mb-2  {margin-bottom: 2rem;}
.mb-3  {margin-bottom: 3rem;}
.mb-4  {margin-bottom: 4rem;}
.mb-8  {margin-bottom: 8rem;}

.mt-2 {margin-top: 2rem;}
.mt-4 {margin-top: 4rem;}

.ml-2 {margin-left: 2rem;}

.mb-auto {margin-bottom: auto;}
.mt-auto {margin-top: auto;}

.zindextop, .z-index-3 {z-index:999999}
.z-index-2 {z-index:9999}
.z-index-1 {z-index:99}

.grid-col-2 {  grid-column: 2; }

.absolute-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding:8rem 0;
}

.scroll{
  overflow: auto !important;
}

.no-scroll {
  overflow: hidden !important;
}

.d-none, .not-visible {
  display: none;
}

/* END CLASS */
section figure {
  height: 100%;
}
section picture {
  height: 100%;
}

.column {
  height: 75vh;
  width: 100%;
}

.slides {
  height: 100vh;
  display: grid;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.bg-light {
  background-color: var(--light-color);
  color: var(--dark-color);
}

.bg-dark {
  background-color: var(--dark-color);
  color: var(--light-color);
}

.bg-dark .desk-nav, .bg-dark .namecount, .bg-black .desk-nav, .bg-black .namecount {
  color: grey;
}

.desk-nav a {
  -webkit-user-select:none;
  -webkit-touch-callout:none;
  user-select:none;
}
.bg-black {
  background-color: black;
  color: var(--light-color);
}

.gallery {
  position: relative;
  height: 100%;
}

.intro-text {
  flex-direction: column !important;
  height: 100%;
}

.intro-text div:first-of-type {
  z-index: 9;
  padding-top: 11rem;
  box-sizing: border-box;
}

.intro-text h2 {
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 3rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -0.7px;
}

.intro-text p{
  max-width: 764px;
  margin-bottom: 2rem;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.1px;
  text-align: left;
}

.intro-text p:last-of-type{
  margin-bottom: 0rem;
}

.text-small {
  font-size: 13px !important;
  line-height: 16px !important;
}

.italic {
  font-style: italic;
}

.slides img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: contain;
}

.next-prev {
  display: flex;
  flex-direction: row;
  height: 65vh;
  width: 100%;
  position: fixed;
  z-index: 999;
  bottom: 1rem;

  top: 50%;
  transform: translateY(-50%);

  -webkit-tap-highlight-color: transparent;
}

.next-prev a {
  height: 100%;
  flex: 1;
}

.next:hover {
  cursor: url(./static/icons/cursornext.svg), auto;
}

.prev:hover {
  cursor: url(./static/icons/cursorprev.svg), auto;
}

.nav-action, .btn-close {
  color: var(--light-color);
  mix-blend-mode: difference;
  position: fixed;
  top: 0rem;
  left: 0rem;
  padding: 1rem;
  font-size: 2rem;
  z-index: 9999999;
  transition: all .3s ease-in-out;
}

.btn-close {
  top: 0px;
  right: -1rem;
  left: unset;
}


.nav-active {
  transform: rotate(45deg);
}

.nav-active .nav-action{
  transform: rotate(45deg);
}

.offscreen-nav {
  flex-direction: column;
  justify-content: center;
}

.nav-visible {
  display: grid !important;
  place-items: center;
}

.navwrap {
  height: 70vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  position: relative;
}

.nav-name, .nav-about {
  position: absolute;
  width: 100%;
}

.nav-name {
  top: 0rem;
}

.nav-about {
  bottom: 3rem;
}

.offscreen-nav {
  top: 0 !important;
  background: var(--dark-color);
  height: 100%;
  width: 100vw;
  position: fixed;
  z-index: 99999;
  box-sizing: border-box;
  display: none;
  transition: all .3s ease-in-out;
}

.offscreen-nav > * {
  text-shadow: 0 0 2px #a1a1a1;
  mix-blend-mode: difference;
}

.nav-visible{
  display: flex;
  transition: all .3s ease-in-out;
}

.mob-show {
  display: none;
}

.name {
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1rem;
}

.visible {
  display: block !important;
}

.langs button {
 margin-block-start: 2rem;
}

.langs p {
  margin-block-end: 2rem;
}

@media screen and (min-width: 745px){

  .about-grid {
    max-height: 100vh;
    height: 100vh;
    grid-template-columns: 1fr 1fr;
  }

  .about-grid .bio,
  .about-grid .parcour  {
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .about-grid .bio p{
    font-size: 3.25rem;
    line-height: 3.5rem;
    margin-top: -8px;
    letter-spacing: -0.15px;
  }

  .parcour p {
    margin-top: -5px;
  }

  .about-grid  .contact > *{
    flex: 1;
  }

  .about-grid  .contact p {
    margin-bottom: 1rem;
  }

  .about-grid .parcour p{
    font-size: 16px;
    letter-spacing: -0.15px;
    margin-bottom: 18px;
  }

  .about-grid  .parcour h3{
    font-weight: 400;
  }
  .about-grid  .parcour h2{
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .bio, .parcour {
    padding: 2rem;
  }

  .bio::-webkit-scrollbar,
  .parcour::-webkit-scrollbar {
    display: none;
  }

  .parcour {
    padding-right: 4rem;
  }

  .section-footer, .arrow-down {
    display: none;
  }

  .close {
    display: block;
  }

  .nav-action{
    display: none;
  }

  .intro-text, .intro-text p{
    text-align: justify;
  }

  .slides {
    align-content: center;
  }

  .intro-text div:first-of-type {
    z-index: 9;
    height: 100%;
    box-sizing: border-box;
    padding-top: 0rem;
    width: 90%;
    justify-self: center;
  }

  .langs {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
  }

  .en {
    opacity: 0;
  }

  .serie-grid .column {
    width: 75vw;
  }

  .two-img .column {
    width: auto !important;
  }
  .dyp-prev .column{
    width: auto;
  }

  .two-img + .slides .column{
    width: auto;
  }

  .lightbox img, .column {
    height: clamp(40vw, 50vw, 68vh);
  }

  .dyp-prev .column{
    width: -webkit-max-content;
    justify-self: end;
  }

  .dyp-prev .column img{
    width: auto;
  }

  .two-img .column{
    justify-self: end;
    justify-self: baseline;
  }

  .two-img .column:first-child {
    justify-self: end;
  }

  .two-img .column img{
    width: auto;
  }

  .two-img + .slides .column img{
    width: auto;
  }
}

  .desk-hide {
    display: none;
  }

  .longtext .namecount {
    display: none
  }

  .longtext .slides{
    align-content: normal;
  }

  .longtext a, .longtext .counter {
    color: gray;
    text-shadow: 0 0 2px #a1a1a1;
  }

  .longtext .slides h1{
    text-align: center;
    text-transform: uppercase;
    align-self: end;
    margin-bottom: 1rem;
  }

  .longtext .intro-text div:first-of-type {
    height: auto;
  }

  .longtext .desk-hide {
    display: block;
    text-align: center;
    align-self: baseline;
    margin-top: 1rem;
  }

  .overlay{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: white;
    transition: all .3s ease-in-out;
    display: grid;
    place-items: center;
  }

  .overlay-black {
    background: black;
  }

@media screen and (max-width: 744px) {

  .home-body {
    background-color: #0d0d0d;
  }

  canvas {
    transform: translateY(-1.5rem);
  }

  .mob-hide {
    display: none;
  }

  .mob-show {
    display: block;
  }

  .introduction {
    flex-direction: column-reverse;
    justify-content: flex-end;
    z-index: 99999;
  }

  .introduction :first-child {
    height: auto;
  }

  .introduction img {
    display: none;
  }
  .langs {
    margin-top: 8%;
    height: 80%;
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .light-shadow-4 {
    text-shadow: unset;
  }
  .lighter-shadow {
    text-shadow: unset;
  }

  .langs div {
    opacity: 1 !important;
    text-align: center;
    align-self: center;
    justify-self: center;
    padding: 1rem .5rem;
  }
  .langs .fr {
    width: 80%;
  }

  .contact {
    margin-top: 12rem;
  }

  .exp .namecount {
    height: 70vh;
  }

  .grid-col-2 {  grid-column: 1; }

  .soundbtn {
    bottom: 13.5rem;
  }

  .column {
    width: auto;
  }

  .bio p {
    font-size: 24px;
  }

  .lightbox img, .column {
    height: clamp(100vw, 45vw, 40vh);
  }

  .mark .lightbox img, .column {
    height: clamp(100vw, 45vw, 60vh);
  }

  .namecount {
    height: 100%;
    padding: 3rem 0;
    padding-top: 0;
    place-content: space-between;
    position: absolute;
    top: unset;
    transform: unset;
    z-index: 9999;
  }

  .intro-text h2 {
    font-size: 24px;
  }

  /* Series layout style */
  .gallery {
    display: flex;
    align-items: center;
   }
  .slides{
    width: 100vw;
  }
  /* Série slide grid */
  .serie-grid .self-end,
  .serie-grid .self-start {
    justify-self: center;
  }

  .serie-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  /* First Slide */
  .firstSlide .gallery {
    height: 100vh;
  }

  .firstSlide .namecount{
    height: 70vh;
  }

  .firstSlide #series {
    height: unset;
  }

  .firstSlide .counter:first-of-type {
    display: none;
  }

  .firstSlide .counter {
    text-align: center;
    padding: 3rem 0;
    color: var(--main-color);
    text-shadow: 0 0 2px #a1a1a1;
   }
}

@media screen and (max-width: 768px) {
  .namecount { height: 70vh; }
}

.video-outer {
  position: relative;
  width: 100% !important;
  overflow: hidden;
  z-index: 999;
}

.video-outer iframe {
  width: 100% !important;
  height: 100%;
}

.video-inner {
  width: 100% !important;
  height: 100%;
}

.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

/* .transition-slide {
  opacity: 1;
  transform: translateX(0);
  transform-origin: left;
  transition: all .3s ease-in-out;
}

html.is-animating .transition-slide {
  transform: translateX(100%);
  opacity: 0;
} */

/* .lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
} */